import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import CardOutlineIcon from "../../modUtils/components/card/CardOutlineIcon";
// import EntityFidelity from "./EntityFidelity";
import EntityLog from "./EntityLog";
import EntityHistory from "./EntityHistory";
import EntityInformations from "./EntityInformations";
import EntityProfil from "./EntityProfil";
import EntityAccounts from "./EntityAccounts";
import EntitySupports from "./EntitySupports";
import { ReactComponent as InfosIcon } from "../assets/images/infos.svg";
import { ReactComponent as ProfilIcon } from "../assets/images/settings.svg";
import { ReactComponent as AccountIcon } from "../assets/images/account.svg";
import { ReactComponent as SupportsIcon } from "../assets/images/qrcode.svg";
import { ReactComponent as HistoricIcon } from "../assets/images/historic.svg";
// import { ReactComponent as FidelityIcon } from "../assets/images/badge.svg";
import { ReactComponent as LogSvg } from "../assets/images/log.svg";
import { ReactComponent as InfosIconActive } from "../assets/images/infos-bg-white.svg";
import { ReactComponent as ProfilIconActive } from "../assets/images/settings-bg-white.svg";
import { ReactComponent as AccountIconActive } from "../assets/images/account-bg-white.svg";
import { ReactComponent as SupportsIconActive } from "../assets/images/qrcode-bg-white.svg";
import { ReactComponent as HistoricIconActive } from "../assets/images/historic-bg-white.svg";
// import { ReactComponent as FidelityIconActive } from "../assets/images/badge-bg-white.svg";
import { ReactComponent as LogActiveSvg } from "../assets/images/log-bg-white.svg";
import { hasPermission } from "ck_commun/src/app/modUtils/functions/handlePermissions";
import PermissionFadeContainer from "../../modUtils/components/permissionFadeContainer/PermissionFadeContainer";
import { support_centralized_paginated_get } from "../../modSupportCentralized/slices/supportCentralizedSlices";
import i18next from "i18next";

export default function EntityDetailsContainer({
    entity,
    getEntities,
    fetchUpdateEntityCustomer,
    fetchUpdateEntityCompany
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [activeButtonIndex, setActiveButtonIndex] = useState(0);
    const { supportCentralizedCount } = useSelector((state) => state.supportCentralized);
    const { groupId, selectedGroup } = useSelector((state) => state.manager);
    const permissions = selectedGroup?.permissions;
    const groupPermissions = selectedGroup?.plan?.permissions;
    const verifyPermissionManageProfil = hasPermission('mod_profil:manage_profil', permissions);
    const verifyGroupPermissionManageProfil = hasPermission('mod_profil:manage_profil', groupPermissions);
    const verifyPermissionShowSupportCentralized = hasPermission('mod_support_centralized:show_support_centralized', permissions);
    const verifyGroupPermissionShowSupportCentralized = hasPermission('mod_support_centralized:show_support_centralized', groupPermissions);

    const renderContent = () => {
        switch (activeButtonIndex) {
        case 0:
            return (
                <EntityInformations
                    entity={entity}
                    getEntities={getEntities}
                    fetchUpdateEntityCustomer={fetchUpdateEntityCustomer}
                    fetchUpdateEntityCompany={fetchUpdateEntityCompany}
                />
            );
        case 1:
            return <EntityProfil entity={entity}/>;
        case 2:
            return <EntityAccounts entity={entity}/>;
        case 3:
            return <EntitySupports entity={entity}/>;
        case 4:
            return <EntityHistory entity={entity}/>;
        case 5:
            return <EntityLog entity={entity}/>;
        default:
            return (
                <EntityInformations
                    entity={entity}
                    getEntities={getEntities}
                    fetchUpdateEntityCustomer={fetchUpdateEntityCustomer}
                    fetchUpdateEntityCompany={fetchUpdateEntityCompany}
                />
            );
        }
    };

    const handleButtonActivation = (buttonIndex) => {
        setActiveButtonIndex(buttonIndex);
    };

    const getSupportTransactions = async () => {
        await dispatch(
            support_centralized_paginated_get({
                groupId: groupId,
                entityId: entity?.id
            })
        ).unwrap();
    };

    useEffect(() => {
        entity && getSupportTransactions();
    }, [groupId, entity]);

    return (
        <>
        <div className="py-8">
            <div className="flex justify-center">
                <div className="grid grid-row grid-cols-6 gap-2 mx-6 justify-items-center min-[1750px]:mx-28">
                    <div className="cursor-pointer col-span-3 xl:col-span-2 min-[1600px]:col-span-1">
                        <CardOutlineIcon
                            active={activeButtonIndex === 0}
                            color="#00D9A6"
                            label={t("entityDetailsContainer.information")}
                            iconDefault={<InfosIcon/>}
                            iconIsActive={<InfosIconActive/>}
                            onClick={() => handleButtonActivation(0)}
                        />
                    </div>

                    {verifyPermissionManageProfil &&
                        <div className="cursor-pointer col-span-3 xl:col-span-2 min-[1600px]:col-span-1">
                            <PermissionFadeContainer permission={verifyGroupPermissionManageProfil}>
                                <CardOutlineIcon
                                    active={activeButtonIndex === 1}
                                    color="#2D62ED"
                                    label={t("entityDetailsContainer.profile")}
                                    iconDefault={<ProfilIcon/>}
                                    iconIsActive={<ProfilIconActive/>}
                                    onClick={() => handleButtonActivation(1)}
                                />
                            </PermissionFadeContainer>
                        </div>
                    }

                    <div className="cursor-pointer col-span-3 xl:col-span-2 min-[1600px]:col-span-1">
                        <CardOutlineIcon
                            active={activeButtonIndex === 2}
                            color="#F53C56"
                            label={t("entityDetailsContainer.cumulative")}
                            iconDefault={<AccountIcon/>}
                            iconIsActive={<AccountIconActive/>}
                            onClick={() => handleButtonActivation(2)}
                        />
                    </div>

                    {verifyPermissionShowSupportCentralized &&
                        <div className="cursor-pointer col-span-3 xl:col-span-2 min-[1600px]:col-span-1">
                            <PermissionFadeContainer permission={verifyGroupPermissionShowSupportCentralized}>
                                <CardOutlineIcon
                                    active={activeButtonIndex === 3}
                                    color="#0088FF"
                                    label={t("entityDetailsContainer.support")}
                                    iconDefault={<SupportsIcon/>}
                                    iconIsActive={<SupportsIconActive/>}
                                    onClick={() => handleButtonActivation(3)}
                                />
                            </PermissionFadeContainer>
                        </div>
                    }

                    <div className={`col-span-3 xl:col-span-2 min-[1600px]:col-span-1 ${
                        supportCentralizedCount > 0 ? 'cursor-pointer' : 'cursor-not-allowed opacity-30'
                    }
                        `}
                    >
                        <CardOutlineIcon
                            active={activeButtonIndex === 4}
                            color="#008CB3"
                            label={t("entityDetailsContainer.historical")}
                            iconDefault={<HistoricIcon/>}
                            iconIsActive={<HistoricIconActive/>}
                            onClick={() => supportCentralizedCount > 0 && handleButtonActivation(4)}
                        />
                    </div>

                    <div className={`col-span-3 xl:col-span-2 min-[1600px]:col-span-1 ${
                        supportCentralizedCount > 0 ? 'cursor-pointer' : 'cursor-not-allowed opacity-30'
                    }
                        `}
                    >
                        <CardOutlineIcon
                            active={activeButtonIndex === 5}
                            color="#3D09A2"
                            label={t("entityDetailsContainer.log")}
                            iconDefault={<LogSvg/>}
                            iconIsActive={<LogActiveSvg/>}
                            onClick={() => supportCentralizedCount > 0 && handleButtonActivation(5)}
                        />
                    </div>

                    {/* <div className="col-span-3 xl:col-span-2 min-[1600px]:col-span-1">
                        <CardOutlineIcon
                            //active={activeButtonIndex === 5}
                            active
                            color="#3D09A2"
                            label="Fidélité"
                            iconDefault={<FidelityIcon/>}
                            iconIsActive={<FidelityIconActive/>}
                            disabled
                            onClick={() => handleButtonActivation(5)}
                        />
                    </div> */}
                </div>
            </div>

            {/* Render the active content here */}
            <div className="px-10 mt-10">{renderContent()}</div>
        </div>
        </>
    );
};
