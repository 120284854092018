import React from "react";
import { appLogo } from "../../utils/constants";
import HomePage from "../../cklibs/ck_commun/src/app/modUtils/pages/Home/HomePage";

// import WidgetCATopSites from "ck_commun/src/app/modCumulative/components/widgetCATopSites";

export default function Home() {
  return (
    <>
      <HomePage appLogo={appLogo} />
    </>
  );
};
