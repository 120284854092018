import React from 'react'
import { useTranslation } from 'react-i18next';
import { FaArrowRight } from "react-icons/fa";


export default function SubmitButton({ onClick, label, className="" }) {
  const { t } = useTranslation();
  return (
    <button
      htmltype="submit"
      onClick={onClick}
      className={`${className} flex-display justify-content-center uppercase align-items-center bg-success-color px-4 py-2 rounded shadow cursor-pointer font-bold ease-in-out border-0 text-white text-nowrap hover:bg-success-color-600 w-full sm:w-auto`}
    >
      {label ? (
        label
      ) : (
        <>
          {t("common.transButtonExecute")}  <FaArrowRight />
        </>
      )}
    </button>
  )
}
