import React from "react";
import CrmCard from "./crmCard/CrmCard";
import BuildingPage from "ck_commun/src/app/modUtils/components/errorPage/BuildingPage";
export default function CrmDashboard() {
  return (
    // <div className='flex-display'>
    //   <CrmCard icon={process.env.PUBLIC_URL + "/img/promoIcons/widgetPromoIcon.png"} label='total crédit comptes'  amount="3000"></CrmCard>
    //   <CrmCard icon={process.env.PUBLIC_URL + "/img/promoIcons/widgetPromoIcon.png"} label='sevices à facturer'  amount="2000" text='Mois en cours'></CrmCard>
    //   <CrmCard icon={process.env.PUBLIC_URL + "/img/promoIcons/widgetPromoIcon.png"} label='crédits consommés'  amount="2250" text='Mois en cours'></CrmCard>
    //   <CrmCard icon={process.env.PUBLIC_URL + "/img/promoIcons/widgetPromoIcon.png"} label='marketing en cours'  amount="3"></CrmCard>
    //   <CrmCard icon={process.env.PUBLIC_URL + "/img/promoIcons/widgetPromoIcon.png"} label='nombre de sociétés'  amount="50"></CrmCard>
    //   <CrmCard icon={process.env.PUBLIC_URL + "/img/promoIcons/widgetPromoIcon.png"} label="nombre d'utilisateurs" amount="100"></CrmCard>
    // </div>
    <BuildingPage />
  );
};
